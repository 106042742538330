@import "./../theme-bootstrap";

.salon-pad {
  @include breakpoint($medium-up) {
    margin-top: 550px;
  }
}

.slide-comparison {
  width: 640px;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  cursor: col-resize;
  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
  }
  .resized {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;
  }
  .divider {
    position: absolute;
    #{$ldirection}: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    margin-#{$rdirection}: 0;
    background: $color-white;
    &:after {
      @include swap_direction(margin, -20px 0 0 -20px);
      position: absolute;
      top: 46px;
      width: 40px;
      height: 40px;
      content: url(media/export/cms/test/arrow_before-after-nav.svg);
      text-align: center;
      line-height: 1;
      border-radius: 50%;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
  .notice {
    position: absolute;
    #{$ldirection}: 50%;
    top: 0px;
    width: 200px;
    margin-#{$ldirection}: -100px;
    font-size: 20px;
    text-align: center;
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  &:hover {
    .notice {
      opacity: 0;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .divider {
      &:after {
        @include swap_direction(margin, -24px 0 0 -24px);
        top: 46px;
        width: 48px;
        height: 48px;
      }
    }
  }
}

.offer-details-collapse {
  padding-top: 15px;
  font-size: 14px;
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;
  .collapsible-block__title {
    text-align: center;
    span {
      text-decoration: underline;
    }
  }
  .collapsible-block__icon {
    position: relative;
    #{$rdirection}: 0;
    padding-#{$ldirection}: 10px;
    top: -4px;
    &:after {
      font-size: 12px;
    }
  }
  .collapsible-block {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
